import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../Utils";
import { Link } from "react-router-dom";
import _ from "lodash";
import {
  GetCustomerCreditLine,
  closeCreditRequest,
} from "../../../Services/Api/customer";
// import format from "date-fns/format";
import * as moment from "moment";
import { ErrorToast, CloseCredit, SuccessToast } from "../../../Utils/SweetAlert";
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from "@mui/material/IconButton";
import format from "date-fns/format";
import Select from "react-select";
import { showCustName } from "../../../Utils/CommanFunctions";


const statusArr = [
  { label: "All", value: "all" },
  { label: "Open", value: "open" },
  { label: "Closed", value: "closed" }
];

const CustomerCreditLineReport = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [creditLine, setCreditLine] = useState([]);

  const getDueCreditList = async (limitO = "", pageO = "", status = "") => {
    setIsLoading(true);
    await GetCustomerCreditLine({ limit: limitO, page: pageO, status })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setCreditLine(res?.data?.data?.due_credits);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setCreditLine([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setCreditLine([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };
  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const closeCreditRequests = (id, amount, currency, full_amount) => {
    let postObj = { credit_request_id: id, amount: amount }
    CloseCredit({ denyButtonText: "Cancel", amount: full_amount, currency }).then(async (result) => {
      if (result.isConfirmed) {
        // postObj.amount = parseFloat(result?.value?.amount);
        postObj.close_credit_reason = result?.value?.reason;
        await closeCreditRequest(postObj)
          .then((res) => {
            SuccessToast(res?.data?.message || "Credit request close successfully.");
            getDueCreditList(limit, page);
          })
          .catch((err) => {
            if (typeof err.response.data.message !== "undefined") {
              ErrorToast(err.response.data.message || "Server Error!!");
            } else {
              ErrorToast(err?.message || "Server Error!!");
            }
          });
      }
    });
  };

  const handleFilterData = (status) => {
    setPage(0);
    getDueCreditList(limit, page, status?.value || statusArr[0]?.value);
  };

  useEffect(() => {
    getDueCreditList(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>CPay | Admin Customer Credit Line</title>
      </Helmet>
      <div>
        <div className="main-wrapper">
          <div className="page-wrapper">
            <div className="content container-fluid">
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h3 className="page-title">Customer Credit Line</h3>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/admin/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Customer Credit Line
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-2">
                  <div className="multipleSelection border-selection">
                    <Select
                      options={statusArr}
                      defaultValue={statusArr[0]}
                      onChange={(e) => handleFilterData(e)}
                    ></Select>
                  </div>
                </div>
                  <div className="col-auto">
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card card-table">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-center table-hover datatable">
                          <thead className="thead-light">
                            <tr>
                              <th>Action</th>
                              <th>Reference Number</th>
                              <th>Customer</th>
                              <th>Amount</th>
                              <th>Remaining Amount</th>
                              <th>Amount Used</th>
                              <th>Amount Paid</th>
                              <th>Amount Due</th>
                              <th>Due Date</th>
                              <th>Close Amount</th>
                              <th>Closed by user</th>
                              <th>Closed Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!isLoading && (
                              <>
                                {creditLine.map((credit, index) => (
                                  <tr key={index}>
                                    {/* <Tooltip title={!credit?.remaining_amount ? credit?.close_credit_reason : ""}> */}
                                    <td>
                                      {credit?.status == 1 ?
                                      <>
                                      <span className="btn btn-primary me-1" onClick={() => closeCreditRequests(credit._id, credit?.remaining_amount, credit?.currency, credit?.amount)}>Close Credit</span>
                                      </>
                                      :
                                      <>
                                      {credit?.status == 3 ? <>
                                        <span className="badge badge-pill bg-danger-light">Closed</span>
                                        {credit?.close_credit_reason ?
                                        <>
                                          <Tooltip title={credit?.close_credit_reason || ""}>
                                            <IconButton>
                                              <InfoIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </> : <></>
                                        }
                                      </> : ""}
                                      
                                      </>
                                      }
                                    </td>
                                      {/* </Tooltip> */}
                                    <td>
                                      {credit?.reference_number}
                                      <br />
                                    </td>
                                    <td>
                                      {
                                        (credit?.user?.name && credit?.user?.company_name)
                                        ?
                                          showCustName(credit?.user?.name, credit?.user?.company_name)
                                        :
                                          (credit?.user?.name)
                                          ?
                                            credit?.user?.name
                                          :
                                            ""
                                      }
                                    </td>
                                    <td>
                                      <span className="currency-type-text">
                                        {credit?.currency}{" "}
                                      </span>
                                      {credit?.amount?.toFixed(2) || 0}
                                    </td>
                                    <td>
                                      <span className="currency-type-text">
                                        {credit?.currency}{" "}
                                      </span>
                                      {credit?.remaining_amount?.toFixed(2)|| 0}
                                    </td>
                                    <td>
                                      <span className="currency-type-text">
                                        {credit?.currency}{" "}
                                      </span>
                                      {/* {(
                                        credit?.amount -
                                        (credit?.remaining_amount ? credit?.remaining_amount : credit?.close_credit_line?.amount || 0)
                                      ).toFixed(2)} */}
                                      {credit?.used_amount?.toFixed(2) || 0}
                                    </td>
                                    <td>
                                      <span className="currency-type-text">
                                        {credit?.currency}{" "}
                                      </span>
                                      {credit?.amount_paid?.toFixed(2) || 0}
                                    </td>
                                    <td>
                                      <span className="currency-type-text">
                                        {credit?.currency}{" "}
                                      </span>
                                      {/* {(
                                        credit?.amount -
                                        (credit?.remaining_amount ? credit?.remaining_amount : credit?.close_credit_line?.amount || 0) -
                                        credit?.amount_paid
                                      ).toFixed(2)} */}
                                      {/* {credit?.due_amount?.toFixed(2) || 0} */}
                                      {credit?.amount_due?.toFixed(2) || 0}
                                    </td>
                                    <td align="center">
                                      {/* {format(new Date(credit?.due_date),"dd MMMM, yyyy")} */}
                                      {credit?.due_date ? moment(credit?.due_date).format("ll") : "-"}
                                    </td>
                                    <td>
                                      {credit.status === 3 ? (<>
                                        <span className="currency-type-text">
                                        {credit?.currency}{" "}
                                      </span>
                                      {credit?.amount?.toFixed(2) || 0}
                                      </>) : "-"}
                                    </td>
                                    <td>
                                    {credit?.close_credit_by ? (
                                      <>
                                        {credit?.close_credit_user?.name} <br></br>
                                        {credit?.close_credit_user?.mobile
                                          ? "(" + credit?.close_credit_user?.mobile + ")"
                                          : ""}
                                      </>
                                    ) : (
                                      <>-</>
                                    )}
                                    </td>
                                    <td>
                                    {credit?.close_credit_at ? (
                                        format(new Date(credit?.close_credit_at), "dd MMMM, yyyy hh:mm a")
                                    ) : (
                                      <>-</>
                                    )}
                                    </td>
                                  </tr>
                                ))}
                                {creditLine?.length === 0 && (
                                  <tr>
                                    <td colSpan={12}>No Records Found.</td>
                                  </tr>
                                )}
                              </>
                            )}
                            <Pagination
                              totalItem={totalRecords}
                              itemsPerPage={limit}
                              page={page}
                              handleChangePage={handlePageChange}
                              handleChangeRowsPerPage={handleLimitChange}
                              colSpan={12}
                            />
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" container-fluid absolute mb-4">
              <footer className="text-center text-lg-start">
                <div className="text-center">Powered by Alsaffron System.</div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerCreditLineReport;
