import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Pagination } from "../../../Utils";
import { CloseMerchantCredit, ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { CloseMerchantCreditRequest, GetMerchantCreditRequestPaginate } from "../../../Services/Api/master";
import format from "date-fns/format";
import { Helmet } from "react-helmet-async";

const MerchantListAllCreditRequest = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [merchantCreditRequest, setMerchantCreditRequest] = useState([]);

  const getmerchantList = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await GetMerchantCreditRequestPaginate({ limit: limitO, page: pageO })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setMerchantCreditRequest(res?.data?.data?.credit_request);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setMerchantCreditRequest([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setMerchantCreditRequest([]);
        setTotalRecords(0);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getmerchantList(limit, page);
  }, [limit, page]);

  const closeCreditRequest = async (fileId) => {
    CloseMerchantCredit("Close Credit ?").then(async (confirm) => {
      if (confirm.isConfirmed) {
        const postData = {
          credit_request_id: fileId?.id,
          amount: fileId?.amount,
          close_credit_reason: confirm.value,
        };
        await CloseMerchantCreditRequest(postData)
          .then(async (res) => {
            SuccessToast(
              res?.data?.message || "Credit Request Closed successfull."
            );
            getmerchantList(limit, page);
          })
          .catch((err) => {
            if (typeof err.response.data.message !== "undefined") {
              ErrorToast(err.response.data.message || "Server Error!!");
            } else {
              ErrorToast(err?.message || "Server Error!!");
            }
          });
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>CPay | Admin Merchant CreditRequest List</title>
      </Helmet>
      <div>
        <div className="main-wrapper">
          <div className="page-wrapper">
            <div className="content container-fluid">
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h3 className="page-title">Credit Request</h3>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/admin/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Credit Request</li>
                    </ul>
                  </div>
                  <div className="col-auto">
                    <Link
                      to="/admin/merchant-credit-request-add"
                      className="btn btn-primary me-1"
                    >
                      <i className="fas fa-plus" /> Add Credit Amount
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card card-table">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-center table-hover datatable">
                          <thead className="thead-light">
                            <tr>
                              <th>#</th>
                              <th>Action</th>
                              <th>Status</th>
                              <th>Reference Number</th>
                              <th>Requested By</th>
                              <th>Merchant</th>
                              <th>Requested Amount</th>
                              <th>Amount</th>
                              <th>Remaining Credit Amount</th>
                              <th>Due Credit Amount</th>
                              <th>Amount Paid</th>
                              <th>Reason</th>
                              <th>Comment</th>
                              <th>Reviewed By</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!isLoading && (
                              <>
                                {merchantCreditRequest.map(
                                  (merchant, index) => (
                                    <tr key={index}>
                                      <td>{limit * page + (index + 1)}</td>
                                      <td>
                                      {merchant?.status === 1 ? (
                                          <span className="btn btn-primary me-1" onClick={() => closeCreditRequest({id: merchant._id, amount: merchant?.remaining_amount})}>Close Credit</span>
                                        ) : "-"}
                                      </td>
                                      <td>
                                        {merchant.status === 1 && (
                                          <div className="badge badge-pill bg-success-light">
                                            Approved
                                          </div>
                                        )}
                                        {merchant.status === 2 && (
                                          <div className="badge badge-pill bg-danger-light">
                                            Rejected
                                          </div>
                                        )}
                                        {merchant.status === 3 && (
                                          <div className="badge badge-pill bg-info-light">
                                            Closed
                                          </div>
                                        )}
                                        {merchant.status === 0 && (
                                          <div className="badge badge-pill bg-warning-light">
                                            {" "}
                                            Pending
                                          </div>
                                        )}
                                      </td>
                                      <td>
                                        {merchant?.reference_number}
                                        <br />
                                        <span>
                                          {format(
                                            new Date(merchant.createdAt),
                                            "dd MMMM, yyyy , hh:mm a"
                                          )}
                                        </span>
                                      </td>
                                      <td>
                                        {merchant?.related_user?.name || "-"}
                                      </td>
                                      <td>{merchant?.user?.name || "-"}</td>
                                      <td>
                                        <span className="currency-type-text">
                                          {merchant?.currency || 0}{" "}
                                        </span>
                                        {(merchant?.amount_requested || 0).toFixed(2)}
                                      </td>
                                      <td>
                                        <span className="currency-type-text">
                                          {merchant?.currency || 0}{" "}
                                        </span>
                                        {(merchant?.amount || 0).toFixed(2)}
                                      </td>
                                      <td>
                                        <span className="currency-type-text">
                                          {merchant.currency}{" "}
                                        </span>{" "}
                                        {(merchant?.remaining_amount || 0).toFixed(2)}
                                      </td>
                                      <td>
                                        <span className="currency-type-text">
                                          {merchant?.currency}{" "}
                                        </span>
                                        {(merchant?.amount_due || 0).toFixed(2)}
                                      </td>
                                      <td>
                                        <span className="currency-type-text">
                                          {merchant?.currency}{" "}
                                        </span>{" "}
                                        {(merchant?.amount_paid || 0).toFixed(2)}
                                      </td>
                                      <td>{merchant?.reason || "-"}</td>
                                      <td>
                                        {merchant?.status_comments || "-"}
                                      </td>
                                      <td>
                                        {merchant?.statusUpdatedBy?.name || "-"}
                                      </td>
                                    </tr>
                                  )
                                )}
                                {merchantCreditRequest.length === 0 && (
                                  <tr>
                                    <td colSpan={14}>No Records Found.</td>
                                  </tr>
                                )}
                              </>
                            )}
                            <Pagination
                              totalItem={totalRecords}
                              itemsPerPage={limit}
                              page={page}
                              handleChangePage={handlePageChange}
                              handleChangeRowsPerPage={handleLimitChange}
                              colSpan={14}
                            />
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="content container-fluid absolute px-2">
                  <footer className="text-center text-lg-start">
                    <div className="text-center pb-4">
                      Powered by Alsaffron System.
                    </div>
                  </footer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MerchantListAllCreditRequest;
